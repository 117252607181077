@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '@fontsource/work-sans/latin.css';
@import '@fontsource/roboto/latin.css';
@import "./pageSlice.css";


:root {
  /* Copy from Figma */
  --purple800: #7A27EF;
  --purple900: #9146FC;
  --color-row-required: #29B6F60A;
  --color-mapping-layout-bg: #D3E1EB;
  --bg-color: #D3E1EB;
  --icon-color: #0000008A;
  --bg-url: url('../images/bkgd.png');
  --loading-url: url('../images/loading.gif');
  --logo-light: url('../images/logo-light.png');

  --primary-contrast: #FFF;
  --background-color: #D3E1EB;
  --success-main: #2E7D32;
  --error-main: #D32F2F;
  --info-main: #0288D1;
  --text-secondary: rgba(0, 0, 0, 0.60);
  --neutral-09: #111827;
  --neutral-08: #384252;
  --neutral-07: #4B5563;
  --neutral-06: #6B7280;
  --neutral-05: #9CA3B0;
  --white: #FFF;
  --black: #000;
  --gray-300: #E0E0E0;
  --indigo-02: #9B67C9;
  --violet-primary: #942C80;

  --color-positive-01: #013349;
  --color-positive-02: #03A9F4;
  --color-positive-03: #240042;
  --color-positive-04: #7A34B7;
  --color-positive-05: #012862;
  --color-positive-06: #0363F4;
  --color-positive-07: #240A1C;
  --color-positive-08: #B5338A;
  --color-positive-09: #02557A;
  --color-positive-10: #35BAF6;
  --color-positive-11: #491F6E;
  --color-positive-12: #955DC5;
  --color-positive-13: #023B92;
  --color-positive-14: #3582F6;
  --color-positive-15: #481437;
  --color-positive-16: #C45CA1;
  --color-positive-17: #0287C3;
  --color-positive-18: #68CBF8;
  --color-positive-19: #622A92;
  --color-positive-20: #AF85D4;
  --color-positive-21: #024FC3;
  --color-positive-22: #68A1F8;
  --color-positive-23: #91296E;
  --color-positive-24: #D385B9;

  --color-negative-01: #F6396C;
  --color-negative-02: #62172B;
  --color-negative-03: #F4695C;
  --color-negative-04: #622A25;
  --color-negative-05: #F8870E;
  --color-negative-06: #633606;
  --color-negative-07: #FCCD25;
  --color-negative-08: #65520F;
  --color-negative-09: #F86189;
  --color-negative-10: #942241;
  --color-negative-11: #F6877D;
  --color-negative-12: #923F37;
  --color-negative-13: #F99F3E;
  --color-negative-14: #955108;
  --color-negative-15: #FDD751;
  --color-negative-16: #977B16;
  --color-negative-17: #FA88A7;
  --color-negative-18: #C52E56;
  --color-negative-19: #F8A59D;
  --color-negative-20: #C3544A;
  --color-negative-21: #FBB76E;
  --color-negative-22: #C66C0B;
  --color-negative-23: #FDE17C;
  --color-negative-24: #CAA41E;

  --header-gradient: linear-gradient(270deg, #006064 0%, #293252 100%);
  --chart-header-gradient: linear-gradient(270deg, #0097A7 0%, #293252 100%);
  --capsuleRadius: 120px;

  /* variables of card.ts */
  --pattern-card-background-image: url("../images/patternbg.png"), linear-gradient(90deg, #293252, #006064);
}

body {
  background-color: var(--background-color) !important;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

#threshold-input[type=number]::-webkit-outer-spin-button,
#threshold-input[type=number]::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

#threshold-input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

@keyframes slideIn {
   from {
       transform: translateX(100%);
   } 

   to {
       transform: translateX(0);
   }
}

@keyframes slideOut {
   from {
       transform: translateX(0);
   } 

   to {
       transform: translateX(100%);
   }
}

.slide-enter-active {
    animation: slideIn 300ms ease-in;
    z-index: 2;
}

.back .slide-exit-active {
    animation: slideOut 400ms ease-in;
    z-index: 2;
}

.back .slide-enter-active {
    animation: none;
    z-index: 1;
}

.slide-enter-done {
    z-index: 2;
}

.back .slide-exit-done {
    z-index: -1;
}
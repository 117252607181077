.react-datepicker {
  border: none;
  color: inherit;
  width: 100%;
}

.react-datepicker-popper {
  z-index: 10;
  width: 320px;
}

.react-datepicker__header {
  border: none;
  background-color: transparent;
}

.react-datepicker__month-container
, .react-datepicker__quarter-container
, .react-datepicker__year-container {
  width: 100%;
  border-right: 1px solid;
}

.react-datepicker__month-container:last-child {
  border-right: none;
}

.react-datepicker__month-wrapper
, .react-datepicker__quarter-wrapper
, .react-datepicker__year-wrapper {
  display: flex;
  max-width: unset;
}

.react-datepicker__month-text
, .react-datepicker__quarter-text
, .react-datepicker__year-text {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 36px;
  margin: 8px 0px !important;
  height: 36px !important;
  width: 72px !important;
  border-radius: 18px !important;
  display: flex !important;
  justify-content: center;
}

.react-datepicker__day--keyboard-selected:not([aria-disabled=true]):hover
, .react-datepicker__month-text--keyboard-selected:not([aria-disabled=true]):hover
, .react-datepicker__quarter-text--keyboard-selected:not([aria-disabled=true]):hover
, .react-datepicker__year-text--keyboard-selected:not([aria-disabled=true]):hover {
  background-color: #f0f0f0 !important;
}

.react-datepicker__month-text--selected:hover
, .react-datepicker__quarter-text--selected:hover
, .react-datepicker__year-text--selected:hover {
  background-color: #29b6f624 !important;
}

.react-datepicker__month-text--selected
, .react-datepicker__quarter-text--selected
, .react-datepicker__year-text--selected {
  background-color: #29b6f614 !important;
  color: var(--text-primary);
}

.react-datepicker__month-text--keyboard-selected
, .react-datepicker__quarter-text--keyboard-selected
, .react-datepicker__year-text--keyboard-selected {
  background-color: transparent;
}

.react-datepicker__month-text {
  flex-basis: 33.3%;
}

.react-datepicker__year-text {
  flex-basis: 25%;
}